<template>
  <div class="home_box">
    <meta
      name="viewport"
      content="user-scalable=0,width=device-width, initial-scale=1.0"
    />
    <div class="content">
      <div class="content_left">
        <div class="form">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>信息填写</span>
            </div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
            >
              <el-form-item label="项目名称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="项目电气描述" prop="desc">
                <el-input
                  type="textarea"
                  v-model="ruleForm.desc"
                  maxlength="200"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <div class="time">
                <el-form-item label="项目地址" prop="selectedOptions">
                  <el-cascader
                    size="large"
                    :options="ruleForm.options"
                    v-model="ruleForm.selectedOptions"
                    @change="handleChange"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="有否尖峰期">
                  <el-switch v-model="ruleForm.hasShark"></el-switch>
                </el-form-item>
                <el-form-item
                  prop="sharpMonth"
                  label="年尖峰月份"
                  v-if="ruleForm.hasShark"
                >
                  <el-select
                    placeholder="请选择年尖峰月份"
                    multiple
                    v-model="sharpMonth"
                  >
                    <el-option label="选择所有" value="all"></el-option>
                    <el-option
                      v-for="item in month"
                      :label="item.label"
                      :value="item.value"
                      :key="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div style="display: flex" v-for="(item, j) in date" :key="j">
                <template v-if="j === 0">
                  <el-form-item
                    label="起始时间"
                    :rules="[
                      {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-time-select
                      placeholder="开始时间"
                      v-model="item.startTime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00',
                      }"
                    >
                    </el-time-select>
                  </el-form-item>
                </template>
                <template v-else>
                  <el-form-item
                    label="起始时间"
                    :rules="[
                      {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-time-select
                      placeholder="开始时间"
                      v-model="item.startTime"
                      :picker-options="{
                        start: date[j - 1].endTime,
                        step: '00:30',
                        end: '24:00',
                      }"
                    >
                    </el-time-select>
                  </el-form-item>
                </template>
                <el-form-item
                  label="结束时间"
                  :rules="[
                    { required: true, message: '不能为空', trigger: 'change' },
                  ]"
                >
                  <el-time-select
                    placeholder="结束时间"
                    v-model="item.endTime"
                    :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '24:00',
                      minTime: item.startTime,
                    }"
                  >
                  </el-time-select>
                </el-form-item>

                <el-form-item
                  label="时段"
                  :rules="[
                    { required: true, message: '不能为空', trigger: 'blur' },
                  ]"
                >
                  <el-select v-model="item.value" placeholder="请选择时段">
                    <el-option label="尖峰" value="sharp"></el-option>
                    <el-option label="高峰" value="peak"></el-option>
                    <el-option label="平期" value="flat"></el-option>
                    <el-option label="谷期" value="valley"></el-option>
                  </el-select>
                </el-form-item>

                <i
                  v-if="j === 0"
                  @click="
                    date[date.length - 1].endTime == '24:00'
                      ? notAdd(date[date.length - 1].endTime)
                      : addDateList()
                  "
                  style="font-size: 20px; color: #25c4fe; margin: 8px 0 0 10px"
                  class="el-icon-circle-plus"
                ></i>
                <i
                  v-if="j > 0"
                  @click="deleteDateList(j)"
                  style="font-size: 20px; color: #25c4fe; margin: 8px 0 0 10px"
                  class="el-icon-remove"
                ></i>
              </div>

              <div class="prices">
                <el-form-item
                  label="高峰电价"
                  prop="electricityPrices.peakPrices"
                >
                  <el-input
                    v-model="ruleForm.electricityPrices.peakPrices"
                    type="number"
                    @keydown="getPeakPrices()"
                  >
                    <!-- <template slot="prepend">高峰电价：</template> -->
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="谷期电价"
                  prop="electricityPrices.valleyPeriodPrices"
                >
                  <el-input
                    v-model="ruleForm.electricityPrices.valleyPeriodPrices"
                    type="number"
                  >
                    <!-- <template slot="prepend">低谷电价：</template> -->
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="平期电价"
                  prop="electricityPrices.flatPrices"
                >
                  <el-input
                    v-model="ruleForm.electricityPrices.flatPrices"
                    type="number"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="尖峰电价"
                  prop="electricityPrices.sharpPrices"
                  v-if="ruleForm.hasShark"
                >
                  <el-input
                    v-model="ruleForm.electricityPrices.sharpPrices"
                    type="number"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="time">
                <el-form-item label="月均工作天数" prop="workDay">
                  <el-input
                    v-model="ruleForm.workDay"
                    type="number"
                    width="300px"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="日充放方式" prop="chargeWay">
                  <el-select
                    v-model="ruleForm.chargeWay"
                    placeholder="请选择日充放方式"
                  >
                    <el-option label="一充一放" value="1"></el-option>
                    <el-option label="两充两放" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="项目计量点数目" prop="num" class="num">
                <el-input v-model="ruleForm.num" type="number"> </el-input>
                <div class="getNum">
                  <el-button type="primary" @click="getNum">确定</el-button>
                </div>
              </el-form-item>
              <template v-for="(item, index) in ruleForm.numArr">
                <div :key="index" class="point">
                  <div class="point-load">
                    <el-form-item
                      label="项目总负荷"
                      :prop="'numArr.' + index + '.load'"
                    >
                      <el-input
                        v-model="ruleForm.numArr[index].load"
                        type="number"
                      >
                        <template slot="append">Kw</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <el-form-item label="用电情况" class="use">
                    <el-table
                      :data="ruleForm.numArr[index].info"
                      border
                      :header-cell-style="{ textAlign: 'center' }"
                      :cecll-style="{ textAlign: 'center' }"
                    >
                      <el-table-column label="日期">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="
                              'numArr.' +
                              index +
                              '.info.' +
                              scope.$index +
                              '.date'
                            "
                          >
                            <el-date-picker
                              v-model="scope.row.date"
                              type="month"
                              format="yyyy-MM"
                              value-format="yyyyMM"
                              placeholder="时间"
                            >
                            </el-date-picker>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column label="高峰电量">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="
                              'numArr.' +
                              index +
                              '.info.' +
                              scope.$index +
                              '.peakPower'
                            "
                          >
                            <el-input
                              type="number"
                              v-model="scope.row.peakPower"
                            >
                              <template slot="append">Kw/h</template>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column label="谷段电量">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="
                              'numArr.' +
                              index +
                              '.info.' +
                              scope.$index +
                              '.valleyPeriodPower'
                            "
                          >
                            <el-input
                              type="number"
                              v-model="scope.row.valleyPeriodPower"
                            >
                              <template slot="append">Kw/h</template>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column label="平段电量">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="
                              'numArr.' +
                              index +
                              '.info.' +
                              scope.$index +
                              '.flatPower'
                            "
                          >
                            <el-input
                              type="number"
                              v-model="scope.row.flatPower"
                            >
                              <template slot="append">Kw/h</template>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column label="尖峰电量">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="
                              'numArr.' +
                              index +
                              '.info.' +
                              scope.$index +
                              '.sharpPower'
                            "
                          >
                            <el-input
                              type="number"
                              v-model="scope.row.sharpPower"
                            >
                              <template slot="append">Kw/h</template>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="addLine">
                      <el-button
                        type="primary"
                        @click="onAdd(index)"
                        style="margin: 10px"
                        >增行</el-button
                      >
                      <el-button
                        type="info"
                        @click="onDelete(index)"
                        style="margin: 10px"
                        >减行</el-button
                      >
                    </div>
                  </el-form-item>
                </div>
              </template>
              <div style="width: 100%">
                <el-row>
                  <el-col :span="6">
                    <el-form-item label="电池类型" prop="batteryType">
                      <el-select
                        v-model="ruleForm.batteryType"
                        clearable
                        placeholder="请选择电池类型"
                      >
                        <el-option
                          v-for="item in batteryTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="设备类型" prop="istallWay">
                      <el-select
                        v-model="ruleForm.istallWay"
                        clearable
                        placeholder="请选择设备类型"
                      >
                        <el-option
                          v-for="item in istallWayOpt"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="6"></el-col>
                </el-row>
              </div>
              <div style="width: 100%">
                <el-row>
                  <el-col :span="6">
                    <el-form-item label="是否自选配置" prop="isForce">
                      <el-switch
                        v-model="ruleForm.isForce"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                      >
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6" v-show="ruleForm.isForce">
                    <el-form-item label="设备数量" prop="deviceNum">
                      <el-input-number
                        v-model="ruleForm.deviceNum"
                        :min="1"
                        :max="100"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6" v-show="ruleForm.isForce">
                    <el-form-item label="设备类型" prop="deviceType">
                      <el-select
                        v-model="ruleForm.deviceType"
                        clearable
                        placeholder="请选择设备类型"
                      >
                        <el-option
                          v-for="(item, index) in deviceList"
                          :key="index"
                          :label="item.name"
                          :value="index"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="是否自动方案配置"
                      prop="isCreate"
                      v-show="ruleForm.isForce"
                      label-width="100"
                    >
                      <el-switch
                        v-model="ruleForm.isCreate"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                      >
                      </el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>

              <div class="system">
                <el-row>
                  <el-col :span="6"
                    ><el-form-item
                      label="系统效率"
                      prop="systemData.efficiency"
                      style="margin-right: 20px"
                    >
                      <el-input
                        v-model="ruleForm.systemData.efficiency"
                        type="number"
                      >
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="6"
                    ><el-form-item
                      label="单次循环衰减"
                      prop="systemData.singleAttenuation"
                      type="number"
                    >
                      <el-input v-model="ruleForm.systemData.singleAttenuation">
                        <!-- <template slot="append">×0.00000001</template> -->
                      </el-input>
                    </el-form-item></el-col
                  >
                  <el-col :span="6"
                    ><el-form-item label="投资方式" prop="value">
                      <el-select
                        v-model="ruleForm.value"
                        clearable
                        placeholder="请选择投资方式"
                      >
                        <el-option
                          v-for="item in ruleForm.investWay"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select> </el-form-item
                  ></el-col>
                  <el-col :span="6"
                    ><el-form-item
                      label="运维费率（占设备售价）"
                      prop="investment.operations"
                      type="number"
                      class="lable"
                    >
                      <el-input v-model="ruleForm.investment.operations">
                      </el-input> </el-form-item
                  ></el-col>
                </el-row>
              </div>
              <div style="display: flex">
                <template v-if="this.ruleForm.value === 2">
                  <el-form-item
                    label="场地分成（占收入）"
                    prop="investment.site"
                    type="number"
                    class="lable"
                  >
                    <el-input v-model="ruleForm.investment.site"> </el-input>
                  </el-form-item>
                </template>
                <template v-if="this.ruleForm.value === 3">
                  <el-form-item
                    label="资金方投资（占总投资）"
                    prop="investment.aInvest"
                    type="number"
                    class="lable"
                  >
                    <el-input v-model="ruleForm.investment.aInvest"> </el-input>
                  </el-form-item>
                  <el-form-item
                    label="建设方投资（占总投资）"
                    prop="investment.bInvest"
                    type="number"
                    class="lable"
                  >
                    <el-input v-model="ruleForm.investment.bInvest"> </el-input>
                  </el-form-item>
                </template>
              </div>
              <div class="submit" style="float: right">
                <el-form-item v-if="ruleForm.numArr.length">
                  <el-button type="primary" @click="submitForm('ruleForm')"
                    >生成报告</el-button
                  >
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </div>
            </el-form>
          </el-card>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="copyright">版权所有：深圳市未蓝新能源科技公司</div>
      <div class="copyright">
        Copyright Shenzhen Weilan New Energy Technology Co., Ltd. All Rights
        Reserved
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
import { deviceModelQuery } from "../../api/siteList";
export default {
  data() {
    return {
      date: [
        {
          startTime: "",
          endTime: "",
          value: "",
          duration: 0,
        },
      ],
      batteryTypeOptions: [
        {
          label: "梯次电池",
          value: 1,
        },
        {
          label: "新电池",
          value: 2,
        },
      ],
      ruleForm: {
        istallWay: "",
        isForce: false,
        isCreate: true,
        deviceNum: 1,
        deviceType: "户外柜",
        isSave: false,
        isUpload: false,
        systemData: {
          efficiency: 0.9,
          singleAttenuation: 0.00008333,
        },
        batteryType: 1,

        chargeWay: 0,
        hasShark: false,
        investWay: [
          {
            value: 1,
            label: "企业自投",
          },
          {
            value: 2,
            label: "场地投资方投资",
          },
          {
            value: 3,
            label: "甲乙双方共同投资",
          },
        ],
        value: "",
        investment: {
          operations: 0.02,
          sales: 0,
          site: 0,
          aInvest: 1,
          bInvest: 0,
        },
        workDay: 22,
        num: 0,
        name: "",
        desc: "",
        load: [],
        address: "",
        // 电价
        electricityPrices: {
          peakPrices: 0,
          valleyPeriodPrices: 0,
          flatPrices: 0,
          sharpPrices: 0,
        },
        // 时长
        electricityDate: {
          peakDate: 7,
          valleyPeriodDate: 8,
          flatDate: 9,
          sharpDate: 0,
        },
        selectedOptions: [],
        options: regionData,
        file: null,
        numArr: [], //用电情况数组对象
      },
      month: [
        {
          value: 1,
          label: "1月",
        },
        {
          value: 2,
          label: "2月",
        },
        {
          value: 3,
          label: "3月",
        },
        {
          value: 4,
          label: "4月",
        },
        {
          value: 5,
          label: "5月",
        },
        {
          value: 6,
          label: "6月",
        },
        {
          value: 7,
          label: "7月",
        },
        {
          value: 8,
          label: "8月",
        },
        {
          value: 9,
          label: "9月",
        },
        {
          value: 10,
          label: "10月",
        },
        {
          value: 11,
          label: "11月",
        },
        {
          value: 12,
          label: "12月",
        },
      ],
      sharpMonth: [],
      address: "",
      rules: {
        chargeWay: [
          { required: true, message: "请选择日充放方式", trigger: "change" },
        ],
        selectedOptions: [
          { required: true, message: "请选择地址", trigger: "blur" },
        ],
        workDay: [
          { required: true, message: "请输入月平均工作天数", trigger: "blur" },
          // {required:true,message:"请输入月平均工作天数",trigger:"blur,change"}
        ],
        num: [{ required: true, message: "请输入计量点个数", trigger: "blur" }],
        info: [{ required: true, message: "请输入用电情况", trigger: "blur" }],
        value: [
          { required: true, message: "请选择项目建设模式", trigger: "change" },
        ],
        batteryType: [
          { required: true, message: "请选择电池类型", trigger: "change" },
        ],
        "electricityPrices.peakPrices": [
          {
            required: true,
            message: "请输入高峰电价",
            trigger: ["change", "blur"],
          },
        ],
        "electricityPrices.valleyPeriodPrices": [
          {
            required: true,
            message: "请输入谷期电价",
            trigger: ["change", "blur"],
          },
        ],
        "electricityPrices.flatPrices": [
          {
            required: true,
            message: "请输入平期电价",
            trigger: ["change", "blur"],
          },
        ],
        "electricityPrices.sharpPrices": [
          {
            required: true,
            message: "请输入尖峰电价",
            trigger: ["change", "blur"],
          },
        ],
        "electricityDate.peakDate": [
          {
            required: true,
            message: "请输入高峰时长",
            trigger: ["change", "blur"],
          },
        ],
        "electricityDate.valleyPeriodDate": [
          {
            required: true,
            message: "请输入谷期时长",
            trigger: ["change", "blur"],
          },
        ],
        "electricityDate.flatDate": [
          {
            required: true,
            message: "请输入平期时长",
            trigger: ["change", "blur"],
          },
        ],
        "electricityDate.sharpDate": [
          {
            required: true,
            message: "请输入尖峰时长",
            trigger: ["change", "blur"],
          },
        ],
        "systemData.efficiency": [
          {
            required: true,
            message: "请输入系统效率",
            trigger: ["change", "blur"],
          },
        ],
        "systemData.singleAttenuation": [
          {
            required: true,
            message: "请输入系统单次循环衰减",
            trigger: ["change", "blur"],
          },
        ],
        "investment.operations": [
          {
            required: true,
            message: "请输入系统运维费率",
            trigger: ["change", "blur"],
          },
        ],
        "investment.site": [
          {
            required: true,
            message: "请输入场地分成比例",
            trigger: ["change", "blur"],
          },
        ],
        "investment.aInvest": [
          {
            required: true,
            message: "请输入资金方投资比例",
            trigger: ["change", "blur"],
          },
        ],
        "investment.bInvest": [
          {
            required: true,
            message: "请输入建设方投资比例",
            trigger: ["change", "blur"],
          },
        ],
      },
      isSet: false,
      setForm: {
        num: 0,
        capacity: 0,
        power: 0,
      },
      istallWayOpt: [
        {
          label: "户外柜",
          value: "户外柜",
        },
        {
          label: "集装箱",
          value: "集装箱",
        },
      ],
      deviceList: [],
      instance: [],
      startDate: [],
    };
  },
  watch: {
    sharpMonth: function (val, oldval) {
      let newindex = val.indexOf("all");
      let oldindex = oldval.indexOf("all"); //获取val和oldval里all的索引,如果没有则返回-1
      if (newindex != -1 && oldindex == -1 && val.length > 1)
        //如果新的选择里有勾选了选择所有选择所有 则 只直线勾选所有整个选项
        this.sharpMonth = ["all"];
      else if (newindex != -1 && oldindex != -1 && val.length > 1)
        //如果操作前有勾选了选择所有且当前也选中了勾选所有且勾选数量大于1  则移除掉勾选所有
        this.sharpMonth.splice(val.indexOf("all"), 1);
    },
  },
  mounted() {
    this.getLocalStorage();
    this.getDeviceList();
    // this.getDuration();
  },
  methods: {
    // 新增
    addDateList() {
      let info = {
        startTime: "",
        endTime: "",
        value: "",
        duration: 0,
      };
      this.date.push(info);
      // this.startDate.push(getStartTime(this.date[index].endTime));
      // console.log(this.startDate);
    },
    // 删除
    deleteDateList(index) {
      this.date.splice(index, 1);
    },
    getPeakPrices() {
      console.log(this.ruleForm.electricityPrices.peakPrices);
    },
    getLocalStorage() {
      let date = JSON.parse(localStorage.getItem("date"));
      let rule = JSON.parse(localStorage.getItem("ruleForm"));
      // let deviceList = JSON.parse(localStorage.getItem("deviceList"));
      if (date.length > 1) {
        this.date = date;
      }
      // if (deviceList == null || deviceList.length != this.deviceList.length) {
      //   this.getDeviceList();
      // }
      console.log(rule.numArr);
      if (rule.isSave) {
        this.ruleForm = rule;
        this.sharpMonth = JSON.parse(localStorage.getItem("sharpMonth"));
        // this.date=JSON.parse(localStorage.getItem("date"));
      }
    },
    onAdd(index) {
      let info = {
        date: "", // 月份
        peakPower: 0, // 高峰电量
        valleyPeriodPower: 0, // 谷期电量
        flatPower: 0, // 平期电量
        sharpPower: 0, //尖峰电量
        psPower: 0,
      };
      this.ruleForm.numArr[index].info.push(info);
    },
    onDelete(index) {
      this.ruleForm.numArr[index].info.pop();
    },
    onAdd2(index) {
      let date = {
        startTime: "",
        endTime: "",
        value: "",
        duration: 0,
      };
      this.date[index].push(date);
    },
    onDelete2(index) {
      this.date[index].pop();
    },
    toTime() {
      this.$router.push({
        path: "/timeAnalyze",
      });
    },
    getNum() {
      this.ruleForm.numArr = [];
      let reg = /^[1-9]\d*$/;
      if (!reg.test(this.ruleForm.num)) {
        this.$message({
          type: "warning",
          message: "请输入正整数!",
        });
      } else {
        for (let i = 0; i < this.ruleForm.num; i++) {
          let obj = {
            load: 0,
            info: [
              {
                date: "", // 月份
                peakPower: 0, // 峰期电量
                valleyPeriodPower: 0, // 谷期电量
                flatPower: 0, // 平期电量
                sharpPower: 0, //尖峰电量
                psPower: 0,
              },
            ],
          };
          this.ruleForm.numArr.push(obj);
        }
      }
    },
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.ruleForm.selectedOptions.length - 1; i++) {
        loc += CodeToText[this.ruleForm.selectedOptions[i]];
      }
      this.ruleForm.address = loc;
    },
    submitForm(formName) {
      console.log(this.ruleForm.systemData.singleAttenuation);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.ruleForm.electricityPrices.peakPrices);

          if (!this.ruleForm.hasShark) {
            this.ruleForm.electricityDate.sharpDate = 0;
            this.ruleForm.electricityPrices.sharpPrices = 0;
          }

          this.ruleForm.isSave = true;
          if (!this.ruleForm.isForce) {
            this.ruleForm.isCreate = true;
          }
          localStorage.setItem("isUpload", JSON.stringify(true));
          localStorage.setItem("ruleForm", JSON.stringify(this.ruleForm));
          localStorage.setItem("sharpMonth", JSON.stringify(this.sharpMonth));
          localStorage.setItem("date", JSON.stringify(this.date));
          this.$router.push({
            path: "/report",
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    toLogin() {
      this.$router.push("/login");
    },
    getDeviceList() {
      deviceModelQuery({ para: {} })
        .then((res) => {
          this.deviceList = res.data.data.content;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    notAdd(endTime) {
      console.log(endTime);
      this.$message({
        type: "error",
        message: "add error",
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/ .el-form-item__content{
  display: flex
  // flex-direction: column
}
/deep/ .el-switch{
    height: 36px !important
  }
// .point /deep/ .el-form-item__content{
//   display flex
//   flex-direction: column
//   align-items: flex-end
// }
/deep/ .el-form-item__label{
  width 130px !important
}
.home_box{
    width 100%
    // height: 1000px
    height:100vh;
    overflow-y:auto;
    background-color: #002140
    .header{
        width 100%
        height: 60px
        background-color: #fff
        display: flex
        justify-content: space-between
        .left{
          display flex
          img{
            height: 30px
            margin: 15px 20px
          }
          .vilion{
            line-height: 60px
            font-size: 20px
            color:#012fa7
            font-weight: 600
          }
        }
        .right{
          line-height: 60px
          margin-right 20px
        }
        .right:hover{
          cursor:pointer
        }

    }
    .content{
        width 100%
        //height: 100%
        .content_left{
            display: flex
            flex-direction: column
            align-items: center
            padding-bottom: 40px
            .form{
                width 80%
                background-color: #fff
                margin: 20px 0
            }
        }

    }
    .prices{
      display: flex
    }
    .time{
      display: flex
    }
}
.system{
  display flex
}

//移动端适配
@media screen and (min-width:600px) and (max-width:1000px){
  /deep/ .el-button{
    padding 9px 5px !important
  }
  /deep/ .el-form-item{
    margin-bottom 5px !important
  }
  /deep/ .el-form-item__label{
    width 80px !important
    font-size 12px !important
    white-space: nowrap
    padding 0 5px 0 0
  }
  /deep/ .el-card__body{
    padding 20px 10px 20px 0 !important
  }
  /deep/ .el-input-group__append{
    font-size 12px !important
    padding 0 10px !important
  }
  /deep/ .el-input__inner{
    font-size 12px !important
    height: 30px !important
    line-height: 30px !important
  }
  /deep/ .el-table{
    margin 0 0 0 5px
  }
  /deep/ .el-table .el-table__cell{
    line-height: 23px !important
  }
  /deep/ .el-table th.el-table__cell{
    font-size 12px !important
  }
  // /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
  //   font-size 12px !important
  //   // line-height: 23px !important
  // }
  .getNum{
    margin-left 10px !important
  }
  .getNum /deep/ .el-button{
    font-size 12px
    padding 7px 10px !important
  }
  .addLine{
    margin-right: 5px
    float: right
  }
  .addLine /deep/ .el-button{
    font-size 12px
    padding 7px 14px !important
  }
  .content_left{
    display block !important
    padding-bottom: 0 !important
    .form{
      width: 100% !important
    }
  }
  .prices{
      display: block !important
    }
    .time{
      display: block !important
    }
    .use /deep/ .el-form-item__label{
      display: none !important
    }

.point /deep/ .el-form-item__content{
  // display block !important
  line-height: 23px !important
}

.point /deep/ .el-input--suffix .el-input__inner{
  padding 0 !important
  // width 70px !important
  text-align center
}
.point /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
  width 165px !important
}
.point /deep/ .el-input-group__append{
  padding 0 !important
}
.point /deep/ .el-input__inner{
  padding 0 !important
  text-align: center
}
.point /deep/ .el-table .cell{
  padding 0 5px
}
.point-load /deep/ .el-form-item__content{
  display flex !important
  // line-height: none !important
}
.point-load /deep/ .el-input-group__append{
  padding 0 10px !important
}
.submit /deep/ .el-form-item__content{
  justify-content: flex-end
}
 .submit /deep/ .el-button{
  width 100px
 }
}
@media screen and (max-width:600px){
  /deep/ .el-icon-date:before{
    content:''
  }
  /deep/ .el-button{
    padding 9px 5px !important
  }
  /deep/ .el-form-item{
    margin-bottom 5px !important
  }
  /deep/ .el-form-item__label{
    width 80px !important
    font-size 12px !important
    white-space: nowrap
    padding 0 5px 0 0
  }
  /deep/ .el-card__body{
    padding 20px 10px 20px 0 !important
  }
  /deep/ .el-input-group__append{
    font-size 12px !important
    padding 0 10px !important
  }
  /deep/ .el-input__inner{
    font-size 12px !important
    height: 30px !important
    line-height: 30px !important
  }
  /deep/ .el-table{
    margin 0 0 0 5px
  }
  /deep/ .el-table .el-table__cell{
    line-height: 23px !important
  }
  /deep/ .el-table th.el-table__cell{
    font-size 12px !important
  }
  // /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
  //   font-size 12px !important
  //   // line-height: 23px !important
  // }
  .getNum{
    margin-left 10px !important
  }
  .getNum /deep/ .el-button{
    font-size 12px
    padding 7px 10px !important
  }
  .addLine{
    margin-right: 5px
    float: right
  }
  .addLine /deep/ .el-button{
    font-size 12px
    padding 7px 14px !important
  }
  .content_left{
    display block !important
    padding-bottom: 0 !important
    .form{
      width: 100% !important
    }
  }
  .prices{
      display: block !important
    }
    .time{
      display: block !important
    }
    .use /deep/ .el-form-item__label{
      display: none !important
    }

.point /deep/ .el-form-item__content{
  display block !important
  line-height: 23px !important
}
// .point /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
//   width 70px !important
// }
.point /deep/ .el-input--suffix .el-input__inner{
  padding 0 !important
  width 70px !important
  text-align center
}
.point /deep/ .el-input-group__append{
  padding 0 !important
}
.point /deep/ .el-input__inner{
  padding 0 !important
  text-align: center
}
.point /deep/ .el-table .cell{
  padding 0 5px
}
.point-load /deep/ .el-form-item__content{
  display flex !important
  // line-height: none !important
}
.point-load /deep/ .el-input-group__append{
  padding 0 10px !important
}
.submit /deep/ .el-form-item__content{
  justify-content: flex-end
}
 .submit /deep/ .el-button{
  width 100px
 }
}
.lable /deep/ .el-form-item__label{
  width:200px !important
}
.addLine{
  display flex
  flex-direction column
}
/deep/ .el-card{
  background:#222e4a !important;
  color:#fff !important
}
/deep/ .el-card__header{
  border-bottom: 1px solid #23b7cb !important
}
/deep/ .el-table--border, .el-table--group{
  border:0px solid #333 !important
}
/deep/ .el-table td.el-table__cell{
  border: 1px solid #27344b !important
}
/deep/ .el-table th.el-table__cell.is-leaf{
  border: 1px solid #27344b !important
}
/deep/ .el-table--border::after, .el-table--group::after{
  width:1px !important
  background: #27344b !important
}
///deep/ .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
//  border:1px solid #333 !important
//}
</style>
<style lang="stylus">
/deep/ .el-table th.el-table__cell.is-leaf{
  border: 1px solid #27344b !important
}
.bottom {
    width: 100%;
    padding-top: 10px;

    .copyright {
      color: black;
      width: 100%;
      text-align: center;
      font-size: 12px;
      padding-bottom: 10px;
    }
  }
</style>
